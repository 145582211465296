video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  margin-top: -80px;
}

.hero-container,
.hero-container > section {
  background: url('/src/HeroSecBackG.gif') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
  object-fit: contain;
}

.hero-container > section {
  align-items: center;
}
.hero-container > section > h1 {
  color: #2d9ca8;
  font-size: 66px;
  margin-top: -100px;
}

.hero-container > section > p {
  margin-top: 8px;
  color: #2d9ca8;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.headers {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}

@media screen and (max-width: 960px) {
  .hero-container > section > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > section > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > section > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
