* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

h1,
h2,
h3,
h5 {
  color: #2b9da8;
}

h1 {
  padding: 3rem 0 2rem;
}

h3 {
  padding: 2rem 0 1rem;
}

p {
  color: #525d68;
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 0.5rem;
  font-family: 'Varela Round', sans-serif;
}

a {
  color: #2b9da8;
}

.home,
.bio,
.portfolio,
.sign-up {
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  height: 90vh;
}

.bio {
  background-image: url('/src/NargesPic.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  padding-top: 200px;
}

.portfolio {
  background-image: url('/src/PortfolioBG2.png');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.text__container > .row {
  max-width: 900px;
  width: 90%;
  margin: 100px auto;
}

.text__container > .row > p {
  line-height: 1.7rem;
}

#contact {
  margin-top: 50px;
}

#contact h2 {
  margin: 20px auto;
}
